//@ts-nocheck
import Axios from 'axios';
import {
  getUserRole,
  getCookieValue,
} from 'dynamic-form-components/lib/service';
import { getEncryptedString } from 'dynamic-form-components/lib/crypto';
import { contextPath } from 'src/constants';
import moment from 'moment';
const config = require(`./config.${process.env.NODE_ENV}.json`);

Axios.interceptors.request.use((axiosConfig: any) => {
  let token=''
  if(getUserRole().includes('US_SPACE_EXTERNAL_USER')){
      token = getCookieValue('donationsAuthToken');
  }
  else{
      token = getCookieValue('authToken');
  }  
  const userId = getCookieValue('userId');
  if (!axiosConfig.headers) {
    axiosConfig.headers = {};
  }
  axiosConfig.headers.Accept = 'text/html, application/json';
  axiosConfig.headers.Authorization = `Bearer ${token}`;
  axiosConfig.withCredentials = true;
  axiosConfig.headers.userId = userId;
  axiosConfig.headers.userRole = getUserRole();
  return axiosConfig;
});

export interface SpaceDonationService {
  getSpaceDonationConfigurations: (data: any) => Promise<any>;
  fetchVerifiedDetailsByCid: ({ queryKey }: any) => Promise<any>;
  fetchFocusAreas: () => Promise<any>;
  fetchStores: ({ queryKey }: any) => Promise<any>;
  fetchNgoEvents: (data: any) => Promise<any>;
  fetchNgoEventCalendar: ({ queryKey }: any) => Promise<any>;
  fetchNgoEventStatusCount: ({ queryKey }: any) => Promise<any>;
  saveEvent: (data: any) => Promise<any>;
  cancelRequestStore: (data: any) => Promise<any>;
  cancelRequestCampaign: (data: any) => Promise<any>;
  fetchSlots: (data: any) => Promise<any>;
  limitBooking: (data: any) => Promise<any>;
  fetchEvents: (data: any) => Promise<any>;
  fetchTopEventsByStatus: (data: any) => Promise<any>;
  fetchNcEventsCard: (data: any) => Promise<any>;
  fetchUserDetails: (data: any) => Promise<any>;
  updateEvent: (data: any) => Promise<any>;
  updateOrgInfo: (data: any) => Promise<any>;
  updateEventRequestDays: (data: any) => Promise<any>;
  autheticate: (data: any) => Promise<any>;
  getMyOrgData: () => Promise<any>;
  setMyOrgData: (data: any) => Promise<any>;
  saveImage: (data: any) => Promise<any>;
  downloadProjectFile: (data: any) => Promise<any>;
  saveCheckoutSurvey: (data: any) => Promise<any>;
  saveImpactSurvey: (data: any) => Promise<any>;
  fetchSpaceAreas: ({ queryKey }: any) => Promise<any>;
  getOtp: ({ queryKey }: any) => Promise<any>;
  doCustomAction: (data: any) => Promise<any>;
  saveSecondaryContact: (data: any) => Promise<any>;
  getSecondaryContact: ({ queryKey }: any) => Promise<any>;
  fetchMessages: () => Promise<any>;
  deleteSecondaryContact: (data: any) => Promise<any>;
  searchStoreNC: (data: any) => Promise<any>;
  saveDraftsNC: (data: any) => Promise<any>;
  getEventInfo: ({ queryKey }: any) => Promise<any>;
  getEventContact: ({ queryKey }: any) => Promise<any>;
  getDownloadModalDetails: () => Promise<any>;
  generateExcelReport: (data: any) => Promise<any>;
  getSelectedStoreByOrgForNC: ({ queryKey }: any) => Promise<any>;
  getNcDetailsWithSelectedStoreByOrgForNC: ({ queryKey }: any) => Promise<any>;
  fetchNcEventDetails: (data: any) => Promise<any>;
  submitRequestNC: (data: any) => Promise<any>;
  getEventDashboardData: (data: any) => Promise<any>;
  getLocalCampaignData: (data: any) => Promise<any>;
  getFiltersCount: (data: any) => Promise<any>;
  getContactList: ({ queryKey }: any) => Promise<any>;
  removeStoresInNC: (data: any) => Promise<any>;
  fetchProposedDates: (data: any) => Promise<any>;
  CalenderAddMoreDatesInfo: (data: any) => Promise<any>;
  downloadFileUrlMutation: (data: any) => Promise<any>;
  updateEventContact: (data: any) => Promise<any>;
  getAllContactDetails: (data: any) => Promise<any>;
  userAgreements: (data: any) => Promise<any>;
  saveCertificate: (data: any) => Promise<any>;
  getAllNcEventContactDetails: (data: any) => Promise<any>;
  updateEventContactNC: (data: any) => Promise<any>;
  fetchUsedEventDays: (data:any)=> Promise<any>;
}

export const buildQueryString = (params: any) => {
  return Object.keys(params)
    .map((key: string) => key + '=' + params[key])
    .join('&');
};

const SpaceDonationDataService: Service = {
  getSpaceDonationConfigurations: ({ queryKey }: any) => {
    return Axios.get(
      `${config.appBaseUrl}/space-app-configs?${buildQueryString(queryKey[1])}`
    )
      .then((response) => response.data.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },

  fetchVerifiedDetailsByCid: ({ queryKey }: any) => {
    return Axios.get(
      `${config.appBaseUrl}/space-donations/user/${queryKey[2] === 'true' ? 'verified' : 'nonverified'
      }/${queryKey[1]}`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },

  fetchFocusAreas: () => {
    return Axios.get(
      `${config.appBaseUrl}/space-app-configs?key=focusArea&countryCode=US`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },

  fetchSpaceAreas: ({ queryKey }: any) => {
    const [storeNbr, countryCode] = queryKey;
    const params = {
      storeNbr,
      countryCode,
      includeNationalCampaignSpaceArea: false,
    };
    return Axios.get(`${config.appBaseUrl}/space-area/byStoreAndCountry`, {
      params,
    })
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },

  fetchStores: ({ queryKey }: any) => {
    return Axios.get(`${config.appBaseUrl}/store-details?key=${queryKey[1]}`)
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  fetchNgoEvents: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/national-campaign/get-events`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  fetchNgoEventCalendar: ({ queryKey }: any) => {
    return Axios.get(
      `${config.appBaseUrl}/space-donations/national-campaign/calender-view?id=${queryKey[1].id}`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  fetchNgoEventStatusCount: ({ queryKey }: any) => {
    return Axios.get(
      `${config.appBaseUrl}/space-donations/national-campaign/events-status-count?ncId=${queryKey[1].ncId}&orgId=${queryKey[1].orgId}`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  cancelRequestStore: (data: any) => {
    return Axios.get(
      `${config.appBaseUrl
      }/space-donations/national-campaign/cancel-event?${buildQueryString(
        data
      )}`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  cancelRequestCampaign: (data: any) => {
    return Axios.get(
      `${config.appBaseUrl
      }/space-donations/national-campaign/cancel-all-events?${buildQueryString(
        data
      )}`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  saveEvent: (data: any) => {
    return Axios.post(`${config.appBaseUrl}/space-donations`, data)
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  fetchSlots: (data: any) => {
    // @ts-ignore
    return Axios.get(
      `${config.appBaseUrl}/space-donations/calender-details?storeNbr=${data.storeNbr}&month=${data.month}&year=${data.year}&spaceLocation=${data.spaceName}`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  limitBooking: (data: any) => {
    // @ts-ignore
    return Axios.get(
      `${config.appBaseUrl}/space-donations/limit-booking?storeNbr=${data.storeNbr}&spaceOrgId=${data.spaceOrgId}&eventDate=${data.eventDate}&spaceOrgType=${data.isOrgVerified}`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  fetchUsedEventDays: (data: any) => {
    // @ts-ignore
    return Axios.get(
      `${config.appBaseUrl}/space-donations/fetch-used-event-days?storeNbr=${data.storeNbr}&spaceOrgId=${data.spaceOrgId}&year=${data.year}`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  fetchEvents: ({ queryKey }: any) => {
    // @ts-ignore
    return Axios.post(`${config.appBaseUrl}/space-donations/dashboard`, {
      orgId: queryKey[1],
    })
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  fetchTopEventsByStatus: ({ queryKey }: any) => {
    // @ts-ignore
    return Axios.post(`${config.appBaseUrl}/space-donations/dashboard`, {
      orgId: queryKey[1],
      statuses: queryKey[2],
      pageSize: 15,
      pageNum: 1,
    })
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  fetchNcEventsCard: ({ orgId }: any) => {
    // @ts-ignore
    return Axios.post(
      `${config.appBaseUrl}/space-donations/national-campaign/events`,
      { orgId }
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  updateEvent: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/complete-task/SPACE_DONATION`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  fetchUserDetails: (orgId: any) => {
    return Axios.get(
      `${config.appBaseUrl}/space-donations/organizations/${orgId}`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  updateOrgInfo: (data: any) => {
    return Axios.put(`${config.appBaseUrl}/space-donations/organizations`, data)
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  updateEventRequestDays: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/national-campaign/add-more-days`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  autheticate: () => {
    return Axios.get(`${config.appBaseUrl}/public/space-donations/authenticate`)
      .then((response) => response.data)
      .catch((error) => {
        if (
          error?.response?.data?.errorMessage ===
          'User email address not validated'
        ) {
          return error.response.data;
        }
        exceptionHandler(error);
      });
  },
  getMyOrgData: () => {
    return Axios.get(
      `${config.appBaseUrl}/space-donations/spaceOrganizationContext`
    )
      .then((response) => response.data)
      .catch((error) => {
        window.location.href = `${contextPath}/space-donation/onboarding`;
        throw error;
      });
  },
  setMyOrgData: (data: any) => {
    return Axios.put(
      `${config.appBaseUrl}/space-donations/spaceOrganizationContext`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  saveImage: ({ file, donationId, countryCode, fileType }) => {
    const data = new FormData();
    data.append('file', file);
    data.append('donationId', donationId);
    data.append('countryCode', countryCode);
    data.append('fileType', fileType);
    return Axios.post(
      `${config.appBaseUrl}/space-donations/uploadFile/SPACE_DONATION`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  saveCheckoutSurvey: ({ feedback, donationId }) => {
    return Axios.put(
      `${config.appBaseUrl}/space-donations/feedback/${donationId}`,
      feedback
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  saveImpactSurvey: ({ feedback, donationId }) => {
    return Axios.put(
      `${config.appBaseUrl}/space-donations/impactSurvey/${donationId}`,
      feedback
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },

  fetchMessages: () => {
    const closedIds = JSON.parse(localStorage.getItem('closedMessageIds') + '');
    // @ts-ignore
    return Axios.post(
      `${config.appBaseUrl}/space-donations/global-messages/npo`,
      {
        date: moment().format('MMM DD, YYYY'),
        time: moment().format('hh:mm a'),
        messageIds: closedIds ? closedIds : [],
      }
    )
      .then((response) => response.data)
      .catch((e) => exceptionHandler(e));
  },
  downloadProjectFile: ({ queryKey }: any) => {
    const fileInfo = {
      fileId: queryKey[1].fileId,
      container: queryKey[1].container,
      directory: queryKey[1].directory,
    };
    const encryptedPdfInfo = encodeURIComponent(getEncryptedString(fileInfo));
    return Axios.get(
      `${config.appBaseUrl}/files?fileInfo=${encryptedPdfInfo}`,
      { responseType: 'blob' }
    )
      .then((response) => {
        return URL.createObjectURL(
          new Blob([response.data], {
            type: `${queryKey[1].type}/${fileInfo.fileId.split('.')[1]}`,
          })
        );
      })
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  getOtp: ({ queryKey }: any) => {
    return Axios.post(
      `${config.appBaseUrl}/public/space-donations/custom-action/otp`,
      { uuid: queryKey[1].uuid, action: queryKey[1].action }
    ).then((response) => response.data);
  },
  doCustomAction: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/public/space-donations/custom-action/perform`,
      data
    ).then((response) => response.data);
  },
  saveSecondaryContact: ({ orgId, data }) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/custom/${orgId}`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  deleteSecondaryContact: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/custom-delete`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  getSecondaryContact: ({ queryKey }: any) => {
    return Axios.get(
      `${config.appBaseUrl}/space-donations/custom/${queryKey[1].orgId}`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  getEventInfo: ({ queryKey }: any) => {
    return Axios.get(
      `${config.appBaseUrl}/space-donations/national-campaign/event-info/${queryKey[1].id}`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  getEventContact: ({ queryKey }: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/national-campaign/event-contact`,
      queryKey[1]
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  getNcDetailsWithSelectedStoreByOrgForNC: ({ queryKey }: any) => {
    return Axios.get(
      `${config.appBaseUrl}/space-donations/national-campaign/confirmation?ncId=${queryKey[1].ncid}&orgId=${queryKey[1].orgid}&status=DRAFT`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  getSelectedStoreByOrgForNC: ({ queryKey }: any) => {
    return Axios.get(
      `${config.appBaseUrl}/space-donations/national-campaign/draft-events?ncId=${queryKey[1].ncid}&orgId=${queryKey[1].orgid}&status=DRAFT`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  searchStoreNC: (data: any) => {
    const { ncId, orgId, key } = data;
    const params = { ncId, orgId, key };
    return Axios.get(
      `${config.appBaseUrl}/space-donations/national-campaign/store-search`,
      { params }
    );
  },
  saveDraftsNC: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/national-campaign/save-draft`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  fetchNcEventDetails: (data: any) => {
    return Axios.get(
      `${config.appBaseUrl}/space-donations/national-campaign/nc-for-org?orgId=${data.queryKey[1].orgId}`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  submitRequestNC: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/national-campaign/submit`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  updateEventContactNC: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/national-campaign/update-event-contact-nc`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  getDownloadModalDetails: () => {
    return Axios.get(
      `${config.appBaseUrl}/space-donations/national-campaign/get-report-fields`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  generateExcelReport: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/national-campaign/ngo/download`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  getEventDashboardData: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/national-campaign/event-list`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  getLocalCampaignData: (queryKey: any) => {
    // @ts-ignore
    return Axios.get(
      `${config.appBaseUrl}/space-donations/get-donation?donationId=${queryKey}`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  getFiltersCount: (queryKey: any) => {
    // @ts-ignore
    return Axios.get(
      `${config.appBaseUrl}/space-donations/national-campaign/get-list-filters?orgId=${queryKey}`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  fetchProposedDates: (data: any) => {
    // @ts-ignore
    return Axios.get(
      `${config.appBaseUrl}/space-donations/national-campaign/ngo-proposed-event-details?ncId=${data.ncId}&orgId=${data.orgId}&storeNumber=${data.storeNumber}`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  CalenderAddMoreDatesInfo: (data: any) => {
    return Axios.put(
      `${config.appBaseUrl}/space-donations/national-campaign/update-ngo-event-calender`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  removeStoresInNC: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/national-campaign/remove-store`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  downloadFileUrlMutation: (data: any) => {
    const encryptedPdfInfo = encodeURIComponent(getEncryptedString(data));
    return Axios.get(
      `${config.appBaseUrl}/files?fileInfo=${encryptedPdfInfo}`,
      { responseType: 'blob' }
    )
      .then((response) => {
        return URL.createObjectURL(
          new Blob([response.data], {
            type: `application/pdf`,
          })
        );
      })
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  getContactList: ({ queryKey }: any) => {
    // @ts-ignore
    return Axios.get(
      `${config.appBaseUrl}/space-donations/national-campaign/org-contacts?orgId=${queryKey[1].orgId}`
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  updateEventContact: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/national-campaign/update-event-contact?ncId=${data.ncId}&orgId=${data.orgId}`,
      data.payload
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  getAllContactDetails: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/national-campaign/event-contacts`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  getAllNcEventContactDetails: (data: any) => {
    return Axios.post(
      `${config.appBaseUrl}/space-donations/national-campaign/nc-event-contacts`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  userAgreements: (data: any) => {
    // @ts-ignore
    return Axios.get(
      `${config.appBaseUrl}/space-app-configs/user-agreements?state=${data.state}&spaceLocation=${data.spaceLocation}`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
  saveCertificate: ({ file, spaceOrganizationId, countryCode, fileType }) => {
    const data = new FormData();
    data.append('file', file);
    data.append('spaceOrganizationId',spaceOrganizationId );
    data.append('countryCode', countryCode);
    data.append('fileType', fileType);
    return Axios.post(
      `${config.appBaseUrl}/space-donations/uploadFile/SPACE_DONATION/certificate`,
      data
    )
      .then((response) => response.data)
      .catch((error) => {
        exceptionHandler(error);
      });
  },
};

export type Service = SpaceDonationService;

const exceptionHandler = (error: any) => {
  if (error.response && error.response.status === 401) {
    window.location.href = `${contextPath}/space-donation/onboarding`;
  }
  throw error;
};

export const getLoggedInUserEmailService = async () => {
  return Axios.get(`${config.appBaseUrl}/space-donations/getLoggedInUserEmail`)
    .then((res) => res.data)
    .catch((e) => exceptionHandler(e));
};

export default SpaceDonationDataService;
n